import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {MarkdownContent, Section} from "../components/Section"
import {FormPrimary} from "../components/Form"
import ContactForm from "../components/ContactForm"
import BlueVector from "../images/blue-pattern.jpg"

const HeroBackground = styled.div`    
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &:after, &:before{
    content: "";
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    clip-path: ellipse(calc(100% + 120px) calc(100% - 60px) at 50% 0);
    @media (min-width: 768px) {
      clip-path: ellipse(calc(100% - 15px) calc(100% + 180px) at 100% 50%);
    }
    @media (min-width: 992px) {
      clip-path: ellipse(calc(100% - 30px) calc(100% + 180px) at 100% 50%);
    }
  }

    &:before{
		background: transparent linear-gradient(270deg, #1383C1 0%, #006EAC 100%) 0% 0% no-repeat padding-box;		
	}
	&:after{
		background:${props => props.bgAfter};
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.15;
		background-position: center right;
    }
`
const Grid = styled.div`
    position: relative;
    @media (min-width: 768px) {
        display: grid;
        align-items:start;
        grid-template-columns:1fr 1fr;
    }
    @media (min-width: 992px) {
        grid-template-columns:1.2fr 1fr;
    }
`

const GridLeft = styled.div`
    position: relative;    
    padding: 30px 15px 30px 15px;
    @media (min-width: 992px) {
        padding: 40px 30px 40px 30px;  
    }
    @media (min-width: 1200px) {
        padding: 60px 30px 60px 30px;  
    }
    @media (min-width: 1600px) {
        padding: 90px 30px 90px 30px;  
    }
    @media (min-width: 1650px) {
        padding: 90px 30px 90px calc(50vw - 810px);  
    }
`
const GridRight = styled.div`
    position: relative;    
    filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.4));    
    
    padding: 30px 30px 90px 15px;
    @media (min-width: 768px) {
        position: sticky;
        top: 64px;
        height: calc(100vh - 64px);
    }
    @media (min-width: 992px) {
        padding: 30px 30px 40px 30px;
    }
    @media (min-width: 1200px) {
        padding: 40px 30px 60px 30px;
        height: calc(100vh - 80px);
        top: 80px;
    }
    @media (min-width: 1600px) {
        padding: 120px 30px 170px 30px;
    }
    @media (min-width: 1650px) {
        padding: 120px calc(50vw - 810px) 170px 30px;
    }
`

const FormSection = styled(FormPrimary)`
    max-width:360px;
    width:100%;
    margin:0 auto;
    @media (min-width: 768px) {
        max-width:300px;
        margin-left: auto;
        margin-right: 0;
    }
    @media (min-width: 992px) {
        max-width:360px;
    }
    @media (min-width: 1200px) {
        max-width:440px;
    }
    @media (min-width: 1440px) {
        max-width:530px;
    }
    .form-action{
        justify-content: center;
        @media (min-width: 768px) {
            justify-content: flex-start;
        }
    }
`

const FormTitle = styled.div`
	color:#fff;
    margin-bottom:20px; 
    font-weight:700;
    font-size:22px;
    line-height:32px; 
    text-align:center;
    @media (min-width: 768px) {
        text-align:left;
    }
    @media (min-width: 1200px) {
        font-size: 28px;
        line-height: 38px;
    }
    @media (min-width: 1600px) {
        font-size: 32px;
        line-height: 42px;
    }
`

const PrivacyPolicyTermsTemplate = ({ location, data }) => (
  <Layout location={location}>
    <Seo title={data.contentfulPrivacyTermsPage.title} />
    <Section pt="0" pb="0" bgColor="#fff" className="section-content">
        <Grid>
            <GridLeft>   
                <MarkdownContent>
                    <h1>{data.contentfulPrivacyTermsPage.title}</h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.contentfulPrivacyTermsPage.content.childMarkdownRemark.html,
                      }}
                    />
                </MarkdownContent>
            </GridLeft>
            <GridRight>
                <HeroBackground bgAfter={`url(${BlueVector})`}>
                    <FormSection>
                        <FormTitle>Got Questions?  EMAIL OUR OFFICE…</FormTitle>
                        <ContactForm location={location} />
                    </FormSection>
                </HeroBackground>                
            </GridRight>
        </Grid>
    </Section>
  </Layout>
)

export default PrivacyPolicyTermsTemplate

export const pageQuery = graphql`
  query CategoryLandingQuery($id: String!) {
    contentfulPrivacyTermsPage(id: { eq: $id }) {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
